.card {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* background-color: rgb(255, 255, 255); */
    width: 17rem;
    /* height: fit-content; */
    /* border-radius: 20px; */
  }
  
  .card img {
    width: 100%;
    /* border-radius: 20px; */
  }
  
  .card h2 {
    margin: 0;
    margin-top: 1rem;
  }
  
  .card p {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .card .btnn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  